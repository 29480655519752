<template>
  <v-dialog v-model="dialog" persistent scrollable width="750">
    <v-card>
      <v-card-title class="py-2">Crear Comité GH</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5 mb-2">
        <div class="px-2">
          <v-row>
            <v-col cols="12" sm="12" class="d-flex py-0">
              <v-text-field
                v-model="titulo"
                class="mr-3"
                dense
                background-color="transparent"
                placeholder="Titulo"
                :readonly="editar[0].ver"
                :disabled="editar[0].ver"
              ></v-text-field>
              <v-menu v-model="menu" top nudge-bottom="174" nudge-left="16" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="color">
                    fas fa-circle
                  </v-icon>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="color" flat :disabled="editar[0].ver" />
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="4" class="py-0">
              <v-menu
                v-model="menuDateInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      fechaInicio
                        .split('-')
                        .reverse()
                        .join('-')
                    "
                    :disabled="editar[0].ver"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  @input="menuDateInicio = false"
                  locale="es-es"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" v-if="!todoDia" class="py-0">
              <v-menu
                ref="menuHoraInicio"
                v-model="menuHoraInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaInicio"
                    dense
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="editar[0].ver"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuHoraInicio"
                  v-model="horaInicio"
                  full-width
                  @click:minute="$refs.menuHoraInicio.save(horaInicio)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" v-if="!todoDia" class="py-0">
              <v-menu
                ref="menuHoraFin"
                v-model="menuHoraFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaFin"
                    dense
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="editar[0].ver"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuHoraFin"
                  v-model="horaFin"
                  :min="horaInicio"
                  full-width
                  @click:minute="$refs.menuHoraFin.save(horaFin)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <span class="d-flex align-center font-weight-bold" md="2" v-if="todoDia"> - </span>
            <v-col cols="12" sm="5" md="5" lg="4" v-if="todoDia" class="py-0">
              <v-menu
                v-model="menuDateFin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      fechaFin
                        .split('-')
                        .reverse()
                        .join('-')
                    "
                    :disabled="editar[0].editar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  :min="fechaInicio"
                  @input="menuDateFin = false"
                  locale="es-es"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="3" lg="3" class="d-flex align-center py-0">
              <v-checkbox
                v-model="todoDia"
                dense
                label="Todo el día"
                :readonly="editar[0].ver"
                :disabled="editar[0].ver"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" class="py-0">
              <v-select
                v-model="seleccion"
                dense
                :items="items"
                background-color="transparent"
                class="pa-0"
                :readonly="editar[0].ver"
                :disabled="editar[0].ver"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="lider"
                dense
                :search-input.sync="searchLider"
                @input="searchLider = null"
                :items="responsablesEmpresa"
                item-text="name"
                item-value="code"
                label="Lider"
                placeholder="Lider"
                prepend-icon="mdi-database-search"
                no-data-text="No se encuentran datos"
                :readonly="editar[0].ver"
                :disabled="editar[0].ver"
                return-object
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip small> {{ data.item.name }} </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="secretario"
                dense
                :search-input.sync="searchSecretario"
                @input="searchSecretario = null"
                :items="responsablesEmpresa"
                item-text="name"
                item-value="code"
                label="Secretario"
                placeholder="Secretario"
                prepend-icon="mdi-database-search"
                no-data-text="No se encuentran datos"
                :readonly="editar[0].ver"
                :disabled="editar[0].ver"
                return-object
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip small> {{ data.item.name }} </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="px-2 pt-3">
          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <v-data-table
                dense
                :headers="editar[0].ver ? cabecerasVer : cabeceras"
                :items="participantes"
                :items-per-page="-1"
                class="border"
                hide-default-footer
                :mobile-breakpoint="0"
                no-data-text="No se encontraron datos"
                no-results-text="No se encontraron datos"
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2">
                    <div class="d-flex align-center white--text body-1">
                      <span :class="!editar[0].ver ? 'text-decoration-underline' : ''">P</span>ARTICIPANTES/INVITADOS
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn
                        color="white"
                        fab
                        icon
                        x-small
                        @click="addRows('P')"
                        v-shortkey="['ctrl', 'p']"
                        @shortkey="addRows('P')"
                        v-if="!editar[0].ver"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.dni`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.dni" v-if="!editar[0].ver">
                    {{ item.dni }}
                    <template v-slot:input>
                      <v-text-field v-model="item.dni" label="Dni" single-line type="number"></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{ item.dni }}</span>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.object"
                    @save="saveName(item.id, item.object, item.new, 'P')"
                    v-if="!editar[0].ver"
                  >
                    {{ item.name }}
                    <template v-slot:input>
                      <div v-if="!item.new" class="d-flex align-baseline">
                        <v-autocomplete
                          v-model="item.object"
                          :search-input.sync="search"
                          :items="responsables"
                          item-text="name"
                          item-value="code"
                          label="Nombres"
                          placeholder="Nombres"
                          prepend-icon="mdi-database-search"
                          no-data-text="No se encuentran datos"
                          hide-no-data
                          hide-selected
                          return-object
                        >
                          <template v-slot:selection="data">
                            {{ data.item.name }}
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle>
                                  {{
                                    `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}`
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                        <v-btn icon color="primary" @click="changeNewEditText(item.id, 'P')">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                      <v-text-field v-else v-model="item.name" label="Nombres" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{ item.name }}</span>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <v-edit-dialog @save="saveEmail(item.id, 'P')" v-if="!editar[0].ver">
                    {{ item.email }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.email"
                        label="Email"
                        :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{ item.email }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteItem(item.id, 'P')">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-data-table
                :headers="editar[0].ver ? cTemasEdit : cTemas"
                dense
                :items="temas"
                :items-per-page="-1"
                class="border"
                hide-default-footer
                :mobile-breakpoint="0"
                no-data-text="No se encontraron datos"
                no-results-text="No se encontraron datos"
              >
                <template v-slot:top>
                  <div class="primary w-100 d-flex justify-space-between px-2">
                    <div class="d-flex align-center white--text body-1">
                      TE<span :class="!editar[0].ver ? 'text-decoration-underline' : ''">M</span>AS A TRATAS
                      <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn
                        color="white"
                        fab
                        icon
                        x-small
                        @click="addRowsTema"
                        v-shortkey="['ctrl', 'm']"
                        @shortkey="addRowsTema"
                        v-if="!editar[0].ver"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <!--<v-toolbar dense flat color="primary">
                    <v-toolbar-title v-if="!editar[0].editar" class="white--text body-2"
                      >TE<u>M</u>AS A TRATAR</v-toolbar-title
                    >
                    <v-toolbar-title v-else class="white--text body-1">TEMAS A TRATAR</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      fab
                      dark
                      x-small
                      class="mb-2"
                      @click="addRowsTema"
                      v-shortkey="['ctrl', 'm']"
                      @shortkey="addRowsTema"
                      v-if="!editar[0].ver"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>-->
                </template>
                <template v-slot:[`item.tema`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.tema" v-if="!editar[0].ver">
                    {{ item.tema }}
                    <template v-slot:input>
                      <v-text-field v-model="item.tema" label="Tema" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{ item.tema }}</span>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-icon small @click="deleteItemTema(item.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <span>No se encontró información</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="error" @click="closePopup()">
          {{ editar[0].ver ? 'Cerrar' : 'Cancelar' }}
        </v-btn>
        <v-btn small color="primary" submit @click="guardarComite(editar[0].editar)" v-if="!editar[0].ver">
          {{ editar[0].editar ? 'Editar' : 'Guardar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import ComiteService from '../services/ComiteService';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { notificateComite } from '@/services/notificationService';
import { decryptAES } from '@/components4x/utils/utils4x';

export default {
  name: 'ComiteFormulario',
  data: () => ({
    showLoading: false,
    comiteService: null,
    lider: [],
    secretario: [],
    menuDateInicio: false,
    menuDateFin: false,
    menuHoraInicio: false,
    horaInicio: null,
    menuHoraFin: false,
    horaFin: null,
    fechaInicio: '',
    fechaFin: '',
    todoDia: false,
    dialogRes: false,
    cTemas: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Tema', value: 'tema', width: '200' },
      { text: 'Acciones', value: 'acciones', width: '50' }
    ],
    cTemasEdit: [
      { text: 'N°', align: 'start', value: 'index', width: '50' },
      { text: 'Tema', value: 'tema', width: '200' }
    ],
    cabeceras: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Acciones', value: 'actions' }
    ],
    cabecerasVer: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' }
    ],
    participantes: [],
    invitados: [],
    tiposResponsables: [
      { value: 'SE', color: 'green', text: 'SECRETARIO' },
      { value: 'LI', color: 'orange', text: 'LIDER' },
      { value: 'IN', color: 'red', text: 'INVITADOS' },
      { value: 'PA', color: 'yellow', text: 'PARTICIPANTES' }
    ],
    responsables: [],
    responsablesEmpresa: [],
    color: '#1976D2FF',
    menu: false,
    titulo: '',
    responsableSeleccionado: [],
    idResponsable: '',
    temas: [],
    searchLider: '',
    searchSecretario: '',
    search: '',
    items: [],
    seleccion: 'NO'
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    editar: {
      type: Array,
      default: () => [{ editar: false, ver: false }]
    }
  },
  watch: {
    todoDia(val) {
      if (val) {
        this.horaInicio = '';
      }
    },
    editar(val) {
      val[0].idlider.forEach((el) => {
        this.responsablesEmpresa.forEach((e) => {
          if (el.idlider == e.code) {
            this.lider.push(e);
          }
        });
      });
      val[0].idsecretario.forEach((el) => {
        this.responsablesEmpresa.forEach((e) => {
          if (el.idsecretario == e.code) {
            this.secretario.push(e);
          }
        });
      });
      this.color = val[0].color;
      this.temas = val[0].temasatratar;
      this.titulo = val[0].titulo;
      this.fechaInicio = val[0].inicio;
      this.fechaFin = val[0].todoDia ? val[0].fin : '';
      this.horaInicio = val[0].todoDia ? '' : val[0].horaInicio;
      this.horaFin = val[0].todoDia ? '' : val[0].horaFin;
      this.todoDia = val[0].todoDia;
      this.seleccion = val[0].seleccion;
      this.minuto = val[0].minuto;
      this.tiempoSeleccionado = val[0].tiempoSeleccionado;
      if (val[0].invitados != null) {
        val[0].invitados.forEach((el) => {
          this.participantes.push({
            id: uuidv4(),
            object: {
              code: el.idresponsable
            },
            dni: el.idtrabajador,
            name: el.nombres,
            email: el.email
          });
        });
      }
    },
    fechaInicio(val) {
      this.fechaFin = this.editar[0].editar && this.editar[0].ver ? this.editar[0].fin : val;
      const dia = new Date(val).getDate() + 1;
      const diaSelect = new Date(val).getDay();
      const mesSelect = new Date(val).getMonth();
      var dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'Domingo'];
      var meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ];
      this.items = [
        { text: 'No se repite', value: 'NO' },
        { text: 'Todos los días', value: 'TO' },
        { text: 'Cada semana, el ' + dias[diaSelect], value: 'CS' },
        { text: 'Todos los meses, el primer ' + dias[diaSelect], value: 'TM' },
        { text: 'Anualmente, el ' + dia + ' de ' + meses[mesSelect], value: 'AE' }
      ];
    },
    horaInicio(val) {
      this.horaFin = this.editar[0].editar ? this.editar[0].horaFin : val;
    }
  },
  methods: {
    clearDialog() {
      this.tiempoSeleccionado = 'M';
      this.temas = [];
      this.todoDia = false;
      this.lider = [];
      this.color = '#1976D2FF';
      this.secretario = [];
      this.seleccion = 'NO';
      this.fechaInicio = new Date().toISOString().substr(0, 10);
      this.participantes = [];
      this.invitados = [];
      this.horaInicio = null;
      this.horaFin = null;
      this.titulo = '';
      this.editar[0].editar = false;
      this.editar[0].ver = false;
    },
    openPopup() {
      this.$emit('open');
    },
    closePopup() {
      this.$emit('close');
    },
    addRowsTema() {
      const index = this.temas.length <= 0 ? 1 : this.temas.length + 1;
      this.temas.push({
        id: uuidv4(),
        index: index.toString().padStart(4, '0'),
        tema: ''
      });
    },
    addRows(tipo) {
      if (tipo == 'P') {
        this.participantes.push({
          id: uuidv4(),
          dni: '',
          name: '',
          email: '',
          new: false,
          object: {}
        });
      } else {
        this.invitados.push({
          id: uuidv4(),
          dni: '',
          name: '',
          email: '',
          new: false,
          object: {}
        });
      }
    },
    clearRow(id, tipo) {
      if (tipo == 'P') {
        const i = this.participantes.findIndex((el) => el.id === id);
        this.participantes.splice(i, 1);
      } else {
        const i = this.invitados.findIndex((el) => el.id === id);
        this.invitados.splice(i, 1);
      }
    },
    saveName(id, object, inew, tipo) {
      if (inew) return;

      if (object === undefined) {
        this.clearRow(id, tipo);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRow(id, tipo);
        return;
      }
      const res = this.responsables.filter((el) => el.dni === dni);
      if (tipo == 'P') {
        this.participantes.map((el) => {
          if (el.id === id) {
            el.dni = res[0].dni;
            el.name = res[0].name;
            el.email = res[0].email;
          }
          return el;
        });
      } else {
        this.invitados.map((el) => {
          if (el.id === id) {
            el.dni = res[0].dni;
            el.name = res[0].name;
            el.email = res[0].email;
          }
          return el;
        });
      }
    },
    deleteItem(id, tipo) {
      if (tipo == 'P') {
        const i = this.participantes.findIndex((el) => el.id === id);
        this.participantes.splice(i, 1);
      } else {
        const i = this.invitados.findIndex((el) => el.id === id);
        this.invitados.splice(i, 1);
      }
    },
    deleteItemTema(id) {
      const i = this.temas.findIndex((el) => el.id === id);
      this.temas.splice(i, 1);
      this.temas.map((el, index) => (el.index = (index + 1).toString().padStart(4, '0')));
    },
    changeNewEditText(id, tipo) {
      if (tipo == 'P') {
        this.participantes.map((el) => (el.new = el.id === id ? true : el.new));
      } else {
        this.invitados.map((el) => (el.new = el.id === id ? true : el.new));
      }
    },
    saveEmail(id, tipo) {
      if (tipo == 'P') {
        this.participantes.map(
          (el) =>
            (el.email =
              el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
        );
      } else {
        this.invitados.map(
          (el) =>
            (el.email =
              el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
        );
      }
    },
    async cargarResponsables() {
      this.showLoading = true;
      const res = await this.comiteService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          this.responsables.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            leader: false,
            new: false,
            object: {}
          });
        });
        res.forEach((el) => {
          if (el.subarea != '') {
            this.responsablesEmpresa.push({
              id: uuidv4(),
              code: el.idresponsable,
              dni: el.idtrabajador,
              name: el.nombresall,
              email: el.email,
              idsubarea: el.idsubarea,
              leader: false,
              new: false,
              object: {}
            });
          }
        });
      } else {
        this.responsables = [];
      }

      this.showLoading = false;
    },
    async guardarComite(aux) {
      const auxInvitados = [];
      const idlider = [];
      const idsecretario = [];
      let res;
      if (this.titulo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un titulo');
        return;
      }
      if (this.participantes.length <= 0) {
        await this.alertDialog('warning', 'Ingrese participantes');
        return;
      }
      let auxDni = false;
      this.participantes.forEach((el) => {
        if (el.dni == '' || el.nombre == '') {
          auxDni = true;
        }
      });
      if (auxDni) {
        this.alertDialog('warning', 'Falta dni/nombres de algunos participantes');
        return;
      }
      if (this.horaInicio == null && this.todoDia == false) {
        await this.alertDialog('warning', 'Ingrese una hora');
        return;
      }
      if (this.lider.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un lider');
        return;
      }
      if (this.secretario.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un secretario');
        return;
      }
      this.lider.forEach((el) => {
        idlider.push({
          idlider: el.code,
          email: el.email
        });
      });
      this.secretario.forEach((el) => {
        idsecretario.push({
          idsecretario: el.code,
          email: el.email
        });
      });
      this.participantes.forEach((el) => {
        if (el.name != '') {
          auxInvitados.push({
            idresponsable: el.object.code != undefined ? el.object.code : '',
            idtrabajador: el.dni,
            nombres: el.name,
            email: el.email
          });
        }
      });
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de guardar los cambios?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        if (!aux) {
          this.showLoading = true;
          res = await this.comiteService.post().execResource('guardar', {
            idempresa: decryptAES(localStorage.getItem('emp')),
            idlider: idlider,
            idsecretario: idsecretario,
            titulo: this.titulo,
            fecha1: this.fechaInicio,
            fecha2: this.todoDia ? this.fechaFin : '',
            hora1: this.todoDia ? '' : this.horaInicio,
            hora2: this.todoDia ? '' : this.horaFin,
            tododia: this.todoDia ? 1 : 0,
            repetir: this.seleccion,
            color: this.color,
            temasatratar: this.temas,
            icon: '',
            invitados: auxInvitados
          });
        } else {
          this.showLoading = true;
          res = await this.comiteService.put().execResource('editar', {
            idcomite: this.editar[0].idcomite,
            idempresa: decryptAES(localStorage.getItem('emp')),
            idlider: idlider,
            idsecretario: idsecretario,
            titulo: this.titulo,
            fecha1: this.fechaInicio,
            fecha2: this.todoDia ? this.fechaFin : '',
            hora1: this.todoDia ? '' : this.horaInicio,
            hora2: this.todoDia ? '' : this.horaFin,
            tododia: this.todoDia ? 1 : 0,
            repetir: this.seleccion,
            color: this.color,
            icon: '',
            invitados: auxInvitados,
            temasatratar: this.temas
          });
        }
        this.showLoading = false;
        if (res.status) {
          if (!aux) {
            const invitados = [];
            let invitadosNoti = [];
            auxInvitados.forEach((el) => {
              invitados.push(el.idresponsable);
            });
            idlider.forEach((el) => {
              invitados.push(el.idlider);
            });
            idsecretario.forEach((el) => {
              invitados.push(el.idsecretario);
            });
            console.log(invitados);
            invitadosNoti = new Set(invitados);
            for (const participant of invitadosNoti) {
              await notificateComite(participant, this.titulo);
            }
          }
          await this.alertDialog('success', res.data);
          this.closePopup();
        } else {
          await this.alertDialog('error', res.data);
        }
        this.$parent.cargarComites();
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    }
  },
  mounted() {
    this.fechaInicio = new Date().toISOString().substr(0, 10);
  },
  async created() {
    this.comiteService = this.$httpService(new ComiteService(), this);
    await this.cargarResponsables();
  }
};
</script>
